<template>
  <div class="page-wrapper downlineEdit-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>View / Edit Reps</h1>
      </div>
      <div class="downlineEdit-card">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="Personal Information"
              @click="
                tabIndex = 0;
                addressType = 'personal';
              "
              active
            >
              <b-card-text>
                <downline-rep-personal-info
                  :personal-info="personalInfo"
                  :tabIndex="tabIndex"
                  @onSuccess="getDetail"
                  @deleteImage="deleteImage"
                  :agent-img="agentImg"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Display Setting"
              @click="
                tabIndex = 1;
                addressType = null;
              "
            >
              <b-card-text>
                <downline-rep-display-setting
                  :settings="displaySettings"
                  :appointmentUrl="appointmentUrl"
                  :tabIndex="tabIndex"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Business Information"
              @click="
                tabIndex = 2;
                addressType = 'business';
              "
            >
              <b-card-text>
                <downline-rep-business-info
                  :business-info="businessInfo"
                  :tabIndex="tabIndex"
                  @onSuccess="getDetail"
                  @deleteImage="deleteImage"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Payment Information"
              @click="
                tabIndex = 3;
                addressType = 'payment';
              "
            >
              <b-card-text>
                <downline-rep-payment-info
                  ref="paymentTab"
                  :payment-method="paymentMethod"
                  :ach-payments="achPayments"
                  :check-payment-addresses="checkPaymentAddresses"
                  :tabIndex="tabIndex"
                  @onSuccess="getDetail"
                />
              </b-card-text>
            </b-tab>
            <!-- <b-tab title="Rep Password">
              <b-card-text>
                <downline-rep-password />
              </b-card-text>
            </b-tab> -->
            <b-tab
              title="Rep Groups"
              @click="
                tabIndex = 4;
                addressType = 'payment';
              "
            >
              <b-card-text>
                <downline-rep-group
                  :rep-groups="repGroups"
                  :tabIndex="tabIndex"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Personalized Sites"
              @click="
                tabIndex = 5;
                addressType = 'personal-sites';
              "
            >
              <b-card-text>
                <downline-rep-personalized-sites
                  :downlineRepId="downlineRepId"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Bio"
              @click="
                tabIndex = 6;
                addressType = 'downline-bio';
              "
            >
              <b-card-text>
                <downline-bio-info
                  :businessSettings="businessSettings"
                  :downLineRepId="downlineRepId"
                />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
        <downline-rep-address-add
          :type="addressType"
          @onSuccess="getDetail"
          @onPaymentModalClose="paymentMethodModalHandler"
          @onSuccessPaymentAdd="onSuccessPaymentHandler"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DownlineRepGroup from "./detail/DownlineRepGroup.vue";
// import DownlineRepPassword from "./detail/DownlineRepPassword.vue";
import DownlineRepPaymentInfo from "./detail/DownlineRepPaymentInfo.vue";
import DownlineRepPersonalInfo from "./detail/DownlineRepPersonalInfo.vue";
import DownlineRepBusinessInfo from "./detail/DownlineRepBusinessInfo.vue";
import DownlineRepDisplaySetting from "./detail/DownlineRepDisplaySetting.vue";
import DownlineRepAddressAdd from "./detail/partials/DownlineRepAddressAdd.vue";
import DownlineRepPersonalizedSites from "./detail/DownlineRepPersonalizedSites.vue";
import DownlineBioInfo from "./detail/DownlineBioInfo.vue";
import Helper from "@/resource/Helper";

export default {
  name: "DownlineRepDetail",
  components: {
    DownlineRepGroup,
    // DownlineRepPassword,
    DownlineRepPaymentInfo,
    DownlineRepPersonalInfo,
    DownlineRepBusinessInfo,
    DownlineRepDisplaySetting,
    DownlineRepAddressAdd,
    DownlineRepPersonalizedSites,
    DownlineBioInfo,
  },
  data: () => ({
    downlineRepId: "",
    personalInfo: [],
    businessInfo: {},
    checkPaymentAddresses: [],
    achPayments: [],
    paymentMethod: "",
    repGroups: [],
    displaySettings: [],
    personalizedSites: [],
    businessSettings: {},
    tabIndex: 0,
    addressType: "personal",
    agentImg: null,
    breadcrumbItems:[],
    appointmentUrl: "",
  }),
  methods: {
    getBreadCrumbs() {
      this.breadcrumbItems = [
        {
          title: "Downline Reps",
          path: {
            name: "DownlineRepList",
          },
          active: false,
        },
        {
          title: "View Reps",
          active: true,
        },
      ];
    },
    onSuccessPaymentHandler() {
      if (this.addressType == "payment") {
        this.$refs.paymentTab.updatePaymentMethodAfterSuccess("check");
      }
    },
    paymentMethodModalHandler() {
      if (this.addressType == "payment") {
        this.$refs.paymentTab.resetPayment();
      }
    },
    async getDetail() {
      this.downlineRepId = atob(this.$route.params.downlineRepId);
      const url = `v2/get-downline-agent-details/${this.downlineRepId}`;
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          let result = res.data.data;
          this.personalInfo = result.personal_information;
          this.businessInfo = result.business_information;
          this.paymentMethod = result.personal_information.default_payment_type;
          this.checkPaymentAddresses = result.check_information;
          this.achPayments = result.ach_information;
          this.repGroups = result.agent_group;
          this.displaySettings = result.display_settings;
          this.agentImg = result.agent_img;
        })
        .catch(() => {
          this.personalInfo = [];
          this.businessInfo = [];
          this.paymentMethod = "";
          this.checkPaymentAddresses = [];
          this.achPayments = [];
          this.repGroups = [];
          this.displaySettings = [];
          this.agentImg = null;
        })
        .finally(() => {
          loader.hide();
        });
    },

    async getBusinessHourDetail() {
      const url = `v2/get-user-setting?agent_id=${this.downlineRepId}`;
      await Api.get(url)
        .then((res) => {
          this.businessSettings = res.data.data.business;
          this.appointmentUrl = res.data.data.appointment_url;
        })
        .catch(() => {
          this.businessSettings = {};
        });
    },
    async deleteImage(type = "") {
      const del = await Helper.confirmDialog(
        "Confirm",
        "Are you sure to delete this image?"
      );
      if (del) {
        Api.delete(`v2/delete-agent-image`, {
          agent_id: this.downlineRepId,
          type: type,
        })
          .then((response) => {
            this.agentImg = null;
            this.getDetail();
            Api.notification(response.data.status, response.data.message);
          })
          .catch((e) => {
            console.log(e);
            Api.notification("error", "Failed to delete image");
          });
      }
    },
  },
  created() {
    this.downlineRepId = atob(this.$route.params.downlineRepId);
    this.getDetail();
    this.getBusinessHourDetail();
    this.getBreadCrumbs()
  },
};
</script>
<style src="@/assets/css/downline-reps.css"></style>
