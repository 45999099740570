<template>
  <div class="addAch-popup">
    <b-modal
      id="downlineRepAchAdd"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <template #modal-header>
        <div class="addAch-header">
          <h4>Add New ACH</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="addAch-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(addAchPayment)"
              ref="addAchContainer"
            >
              <div class="form-group row">
                <input-text
                  :rules="{ required: true }"
                  labelFor="payee_name"
                  labelName="Payee Name"
                  inputName="payee_name"
                  inputClass="payee_name accountInfo-field"
                  :vmodel.sync="form.payee_name"
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                />

                <b-form-group class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <validation-provider
                    name="Routing Number"
                    :rules="{ required: true, digits: 9 }"
                    v-slot="validationContext"
                  >
                    <label id="label-routing-number" for="label-routing-number">
                      Routing Number<span style="color: Red">*</span>
                    </label>
                    <b-form-input
                      id="routing-number"
                      type="number"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback"
                      v-model="form.routing_number"
                      placeholder="Enter Routing Number"
                      @change="updateRoutingNumber"
                    ></b-form-input>
                   
                    <b-form-invalid-feedback
                      id="input-feedback"
                      class="routing-number-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <input-text
                  :rules="{ required: true, /*regex:/^([a-zA-Z0-9 ])*$/ */ }"
                  labelFor="bank"
                  labelName="Bank Name"
                  inputName="bank_name"
                  inputClass="bank_name accountInfo-field"
                  :vmodel.sync="form.bank"
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                  :disabled="true"
                />
              </div>

              <div class="form-group row">
                <input-text
                  :rules="{ required: true, min:4, max:17 }"
                  labelFor="account_number"
                  labelName="Account Number"
                  inputName="account_number"
                  inputClass="account_number info-field"
                  :vmodel.sync="form.account_number"
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                />

                <input-multi-select
                  :rules="{ required: true }"
                  :vmodel.sync="form.account_type"
                  labelName="Account Type"
                  :options="accountTypes.map((type) => type.value)"
                  :custom-label="
                    (opt) => accountTypes.find((x) => x.value == opt).text
                  "
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                  placeholder="Select Account Type"
                  inputClass="account_type"
                />
                <input-multi-select
                  :rules="{ required: true }"
                  :vmodel.sync="form.account_holder_type"
                  labelName="Account Holder Type"
                  :options="accountHolderTypes.map((type) => type.value)"
                  :custom-label="
                    (opt) => accountHolderTypes.find((x) => x.value == opt).text
                  "
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                  placeholder="Select Account Holder Type"
                  inputClass="account_holder_type"
                />
              </div>

              <div class="form-group row">
                <div class="col-12">
                  <div class="signature">
                    <label>Signature</label>
                    <VueSignaturePad
                      width="100%"
                      height="200px"
                      style="border: 1px solid rgb(206, 212, 218)"
                      ref="signaturePad"
                      :options="{ onBegin, onEnd }"
                    />
                    <div class="text-right" style="text-align: right">
                      <a
                        href="#"
                        @click="undo"
                        class="clearSignature"
                        style="
                          background: #0ab5e0;
                          padding: 5px 25px;
                          display: inline-block;
                          border-radius: 4px;
                          margin-top: 10px;
                          color: #fff;
                          text-decoration: none;
                        "
                      >
                        Undo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-100 text-center">
                <b-button class="univ-btn" type="submit">
                  {{ isCreate ? "Adding ACH..." : "Add ACH" }}
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "DownlineRepAchPaymentAdd",
  data: () => ({
    form: {
      payee_name: "",
      bank: "",
      routing_number: "",
      account_number: "",
      account_type: null,
      account_holder_type: null,
      bank_sign: null,
      agent_id: "",
    },
    signErrorText: "",
    accountTypes: Helper.accountTypes(),
    accountHolderTypes: Helper.accountHolderTypes(),
    isCreate: false,
  }),
  methods: {
    closeModal() {
      this.form = {
        payee_name: "",
        bank: "",
        routing_number: "",
        account_number: "",
        account_type: null,
        account_holder_type: null,
        bank_sign: null,
        agent_id: atob(this.$route.params.downlineRepId),
      };
      this.$bvModal.hide("downlineRepAchAdd");
      this.$parent.$emit("onPaymentModalClose");
    },
    reset() {
      this.form = {
        payee_name: "",
        bank: "",
        routing_number: "",
        account_number: "",
        account_type: null,
        account_holder_type: null,
        bank_sign: null,
        agent_id: atob(this.$route.params.downlineRepId),
      };
      this.$refs.observer.reset();
      this.$bvModal.hide("downlineRepAchAdd");
    },
    addAchPayment() {
      this.isCreate = true;
      const url = `v2/add-payment-ach`;
      let loader = this.$loading.show({
        container: this.$refs.addAchContainer,
      });
      this.form.agent_id = atob(this.$route.params.downlineRepId);
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.$parent.$parent.$emit("onSuccess");
          this.$parent.$emit("onSuccessPaymentAdd", "ach");
          this.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCreate = false;
          loader.hide();
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateRoutingNumber() {
      console.log('test')
      let url, pattern, routingNumber;
      routingNumber = this.form.routing_number;
      console.log("routing", routingNumber);
      pattern = new RegExp("^\\d{9}$");
      this.form.bank = "";

      if (routingNumber.match(pattern)) {
        url = `${this.$endpoint.VALIDATE_ROUTING_NUMBER_NEW}${routingNumber}`;
        console.log("mathed", url);
        const otherUrl = axios.create({});
        /*eslint-disable*/
        otherUrl
          .get(url)
          .then((res) => {
            this.form.bank = "";
           
            if (res.data.statusCode == 200) {
              debugger;
              this.form.bank = res.data.data.result.bank_name;
              document
                .querySelector("#routing-number")
                .classList.remove("is-invalid");
            } else if (res.data.statusCode == 404) {
              this.form.bank = "";
              document
                .querySelector("#routing-number")
                .classList.add("is-invalid");
              document.querySelector(".routing-number-feedback").innerHTML =
                "Invalid Routing Number";
            }
          })
          .catch(() => {
            this.form.bank_name = "";
            this.$notify("Invalid Routing Number", "Error", "danger");
            document
              .querySelector("#routing-number")
              .classList.add("is-invalid");
            document.querySelector(".routing-number-feedback").innerHTML =
              "Invalid Routing Number";
          });
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.form.bank_sign = data;
        this.signErrorText = "";
      }
    },
  },
};
</script>