var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrapper"
  }, [_c('h2', [_vm._v("Payment Information")]), _c('p', [_vm._v("Select payment method or add new one.")]), _c('div', {
    staticClass: "paymentInfo-form",
    attrs: {
      "id": "selector"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isAch,
      expression: "isAch"
    }],
    staticClass: "custom-control-input bank-details-switch",
    attrs: {
      "type": "checkbox",
      "id": "customSwitch1"
    },
    domProps: {
      "value": _vm.isAch,
      "checked": Array.isArray(_vm.isAch) ? _vm._i(_vm.isAch, _vm.isAch) > -1 : _vm.isAch
    },
    on: {
      "input": _vm.changePaymentMethod,
      "change": function change($event) {
        var $$a = _vm.isAch,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = _vm.isAch,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isAch = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isAch = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isAch = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-switch-span",
    attrs: {
      "for": "customSwitch1"
    }
  }, [_vm._v(" Supply bank information at a later date ")]), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customSwitch1"
    }
  }, [_vm._v("ACH")])]), _vm.selectedPaymentMethod == 'check' ? [_c('downline-rep-check-payment-address-content', {
    attrs: {
      "addresses": _vm.checkPaymentAddresses
    }
  })] : _vm._e(), _vm.selectedPaymentMethod == 'ach' ? [_c('downline-rep-ach-payment', {
    attrs: {
      "ach-payments": _vm.achPayments
    },
    on: {
      "onSuccessPaymentAdd": _vm.updatePaymentMethodAfterSuccess,
      "onPaymentModalClose": _vm.resetPayment
    }
  })] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }