<template>
  <div class="pcheque form-cheque">
    <h3>Supply bank information at a later date</h3>
    <div class="form-group row ai-location-wrapper" v-if="addresses">
      <label>
        Address Information
        <i
          class="fas fa-plus-circle add-address-btn"
          @click="$bvModal.show('downlineRepAddressAdd')"
        ></i>
      </label>
      <div
        class="col-md-6 col-sm-12 mb-3"
        v-for="(address, index) in addresses"
        :key="index"
      >
        <div
          class="location-div"
          :class="address.is_primary ? 'primary-location' : 'set-as-primary'"
        >
          <p>
            {{ address.checkpayment_address1 }},
            <i
              :class="
                address.is_usps_valid
                  ? 'fas fa-check-circle'
                  : 'fas fa-exclamation-triangle'
              "
              v-b-tooltip.hover
              :title="address.is_usps_valid ? 'Verified' : 'Not Verified'"
            />
            <br />
            {{
              formatAddress2(
                address.checkpayment_address2,
                address.checkpayment_city,
                address.checkpayment_state,
                address.checkpayment_zip,
              )
            }}
          </p>
          <downline-rep-address-content-action
            type="payment"
            :isPrimary="address.is_primary"
            :addressId="address.checkpayment_id"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
        <button type="submit" class="univ-btn">Update</button>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from '@/resource/Helper'
import DownlineRepAddressContentAction from './DownlineRepAddressContentAction.vue'
export default {
  name: 'DownlineRepCheckPaymentAddressContent',
  components: { DownlineRepAddressContentAction },
  props: {
    addresses: {
      required: true,
    },
  },
  methods: {
    formatAddress2(address2, city, state, zip) {
      return Helper.formattedAddress2(address2, city, state, zip)
    },
  },
}
</script>
