<template>
  <span>
    <template v-if="isPrimary">
      <button class="primaryAdrs-btn">Primary</button>
    </template>
    <template v-else>
      <a
        href="javascript:void(0);"
        @click="setPrimary(addressId, type)"
        class="primaryAdrs-btn rep-action-btn"
      >
        Set As Primary
      </a>
    </template>
    <custom-button
      v-if="!isPrimary"
      className="ai-delete-a"
      title="Delete"
      iconClass="fas fa-trash"
      @click.native="deleteAddress(addressId, type)"
    />
  </span>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "DownlineRepAddressContentAction",
  props: {
    type: {
      required: true,
      type: String,
    },
    isPrimary: {
      required: true,
    },
    addressId: {
      required: true,
    },
  },
  methods: {
    setPrimary(id, type) {
      const url = `v2/set-primary-address`;
      this.$confirm({
        title: "Set Primary",
        message: `Do you want to set this address primary?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let agentId = parseInt(atob(this.$route.params.downlineRepId));
            Api.post(url, { agent_id: agentId, address_id: id, type: type })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                if (type == "payment") {
                  this.$parent.$parent.$emit("onSuccess");
                } else {
                  this.$parent.$parent.$parent.$emit("onSuccess");
                }
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deleteAddress(id, type) {
      const url = `v2/delete-agent-address`;
      this.$confirm({
        title: "Delete Address",
        message: `Do you want to delete this address?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let agentId = parseInt(atob(this.$route.params.downlineRepId));
            Api.delete(url, { agent_id: agentId, address_id: id, type: type })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                if (type == "payment") {
                  this.$parent.$parent.$emit("onSuccess");
                } else {
                  this.$parent.$parent.$parent.$emit("onSuccess");
                }
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>
<style >
.rep-action-btn {
  float: right !important;
  margin-top: 3px !important;
  padding: 6px 12px !important;
  margin-right: 35px !important;
}
</style>