<template>
  <div class="pach form-ach">
    <h3>
      ACH
      <i class="fas fa-plus-circle" @click="addAchPayment"></i>
    </h3>
    <div class="fileListing-card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Bank</th>
              <th>Account Number</th>
              <th>Routing</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!achPayments">
              <td :colspan="4" align="center">No records found.</td>
            </tr>
            <tr v-for="(row, index) in achPayments" :key="index">
              <td>
                <span class="date">{{ row.achpayment_name }}</span>
              </td>
              <td>
                <span class="username">{{ row.achpayment_bankname }}</span>
              </td>
              <td>
             
                <span class="username-code">{{ row.achpayment_account }}</span>
              </td>
              <td>
                <span class="username">{{ row.achpayment_routing }}</span>
               
              </td>
              <td>
                <div class="action-icons">
                  <template v-if="row.is_primary">
                    <button class="primaryAdrs-btn">Primary</button>
                  </template>
                  <template v-else>
                    <button
                      href="javascript:void(0);"
                      @click.prevent="setPrimary(row.achpayment_id)"
                      class="primaryAdrs-btn"
                    >
                      Set As Primary
                    </button>
                  </template>
                  <!-- <button class="primaryAdrs-btn">
                    {{ row.is_primary ? "Primary" : "Set As Primary" }}
                  </button> -->
                  <custom-button
                    v-if="!row.is_primary"
                    className="ai-delete-a"
                    title="Delete"
                    iconClass="fas fa-trash"
                    @click.native="deleteAddress(row.achpayment_id)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-pagination">
        <pagination :rows.sync="achPayments" :filters="[]" />
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
        <button type="submit" class="univ-btn">Update</button>
      </div>
    </div>
    <downline-rep-ach-payment-add />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DownlineRepAchPaymentAdd from "./DownlineRepAchPaymentAdd";
export default {
  name: "DownlineRepAchPayment",
  components: { DownlineRepAchPaymentAdd },
  props: {
    achPayments: {
      required: true,
    },
  },
  methods: {
    addAchPayment() {
      this.$bvModal.show("downlineRepAchAdd");
    },
    setPrimary(id) {
      const url = `v2/set-primary-ach`;
      this.$confirm({
        title: "Set Primary",
        message: `Do you want to set this payment primary?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let agentId = parseInt(atob(this.$route.params.downlineRepId));
            Api.post(url, { agent_id: agentId, payment_id: id })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$parent.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deleteAddress(id) {
      const url = `v2/remove-agent-ach`;
      this.$confirm({
        title: "Delete Address",
        message: `Do you want to delete this payment?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let agentId = parseInt(atob(this.$route.params.downlineRepId));
            Api.delete(url, { agent_id: agentId, payment_id: id })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$parent.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>