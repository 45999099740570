<template>
  <div class="form-group row ai-location-wrapper" v-if="addresses">
    <label>
      Address Information
      <i class="fas fa-plus-circle add-address-btn" @click="$bvModal.show('downlineRepAddressAdd')"> </i>
    </label>
    <div
      class="col-md-6 col-sm-12 mb-3"
      v-for="(address, index) in addresses"
      :key="index"
    >
      <div
        class="location-div"
        :class="address.is_primary ? 'primary-location' : 'set-as-primary'"
      >
        <p>
          {{ address.business_address1 }},
          <i
            :class="
              address.is_usps_valid
                ? 'fas fa-check-circle'
                : 'fas fa-exclamation-triangle'
            "
            v-b-tooltip.hover
            :title="address.is_usps_valid ? 'Verified' : 'Not Verified'"
          />
          <br />
          {{
            formatAddress2(
              address.business_address2,
              address.business_city,
              address.business_state,
              address.business_zip
            )
          }}
        </p>
         <downline-rep-address-content-action
            type="business"
            :isPrimary="address.is_primary"
            :addressId="address.business_id"
          />
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import DownlineRepAddressContentAction from "./DownlineRepAddressContentAction.vue";
export default {
  name: "DownlineRepBusinessAddressContent",
  components: { DownlineRepAddressContentAction },
  props: {
    addresses: {
      required: true,
    },
  },
  methods: {
    formatAddress2(address2, city, state, zip) {
      return Helper.formattedAddress2(address2, city, state, zip);
    },
  },
};
</script>