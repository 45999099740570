<template>
  <div class="form-group row ai-location-wrapper" v-if="addresses.length > 0">
    <label>
      Address Information
      <i
        class="fas fa-plus-circle add-address-btn"
        @click="$bvModal.show('downlineRepAddressAdd')"
      >
      </i>
    </label>
    <div
      class="col-md-6 col-sm-12 mb-3"
      v-for="(address, index) in addresses"
      :key="index"
    >
      <div
        class="location-div"
        :class="address.is_primary ? 'primary-location' : 'set-as-primary'"
      >
        <p>
          {{ address.address1 }},
          <i
            :class="
              address.is_usps_valid
                ? 'fas fa-check-circle'
                : 'fas fa-exclamation-triangle'
            "
            v-b-tooltip.hover
            :title="address.is_usps_valid ? 'Verified' : 'Not Verified'"
          />
          <br />
          {{
            formatAddress2(
              address.address2,
              address.city,
              address.state,
              address.zip
            )
          }}
        </p>
        <downline-rep-address-content-action
          type="personal"
          :isPrimary="address.is_primary"
          :addressId="address.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import DownlineRepAddressContentAction from './DownlineRepAddressContentAction.vue';
export default {
  components: { DownlineRepAddressContentAction },
  name: "DownlineRepPersonalAddressContent",
  props: {
    addresses: {
      required: true,
    },
  },
  methods: {
    formatAddress2(address2, city, state, zip) {
      return Helper.formattedAddress2(address2, city, state, zip);
    },
  },
};
</script>