var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.addresses ? _c('div', {
    staticClass: "form-group row ai-location-wrapper"
  }, [_c('label', [_vm._v(" Address Information "), _c('i', {
    staticClass: "fas fa-plus-circle add-address-btn",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('downlineRepAddressAdd');
      }
    }
  })]), _vm._l(_vm.addresses, function (address, index) {
    return _c('div', {
      key: index,
      staticClass: "col-md-6 col-sm-12 mb-3"
    }, [_c('div', {
      staticClass: "location-div",
      class: address.is_primary ? 'primary-location' : 'set-as-primary'
    }, [_c('p', [_vm._v(" " + _vm._s(address.business_address1) + ", "), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      class: address.is_usps_valid ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle',
      attrs: {
        "title": address.is_usps_valid ? 'Verified' : 'Not Verified'
      }
    }), _c('br'), _vm._v(" " + _vm._s(_vm.formatAddress2(address.business_address2, address.business_city, address.business_state, address.business_zip)) + " ")]), _c('downline-rep-address-content-action', {
      attrs: {
        "type": "business",
        "isPrimary": address.is_primary,
        "addressId": address.business_id
      }
    })], 1)]);
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }