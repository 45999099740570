<template>
  <div class="info-wrapper">
    <h2>Display Setting</h2>
    <p>Enter and Update all your Display Setting here.</p>
    <div class="information-form mt-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(updateInfo)"
          ref="downlineRepDisplaySettingContainer"
        >
          <!-- <div class="form-group">
            <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input add-new-address-checkbox"
                type="checkbox"
                id="displayPhone"
                v-model="form.display_phone_flag"
                :value="form.display_phone_flag"
              />
              <label class="form-check-label" for="displayPhone"
                >Display Phone</label
              >
            </div>
            <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input add-new-address-checkbox"
                type="checkbox"
                id="displayEmail"
                v-model="form.display_email_flag"
                :value="form.display_email_flag"
              />
              <label class="form-check-label" for="displayEmail"
                >Display E-mail</label
              >
            </div>
            <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input add-new-address-checkbox"
                type="checkbox"
                id="displayAbout"
                v-model="form.display_about_flag"
                :value="form.display_about_flag"
              />
              <label class="form-check-label" for="displayAbout"
                >Display About</label
              >
            </div>
            <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input add-new-address-checkbox"
                type="checkbox"
                id="addCart"
                v-model="form.display_no_cart_flag"
                :value="form.display_no_cart_flag"
              />
              <label class="form-check-label" for="addCart"
                >NO Add to Cart</label
              >
            </div>
          </div> -->

          <div class="form-group row">
            <input-text
              :rules="{ required: false }"
              labelFor="display-name"
              labelName="Display Name"
              :vmodel.sync="form.agent_web_name"
              formGroupClass="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: false }"
              labelFor="tagline"
              labelName="Tagline "
              :vmodel.sync="form.tagline"
              formGroupClass="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: true, digits: 10, clearoutPhone: true }"
              labelFor="display_phone"
              labelName="Display Phone "
              :vmodel.sync="form.display_phone"
              formGroupClass="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true, neverbounce: true }"
              labelFor="display_email"
              labelName="Display Email "
              :vmodel.sync="form.display_email"
              formGroupClass="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-3"
            />
          </div>

          <!-- <div class="form-group row">
            <input-text
              labelFor="display_name"
              labelName="Display Name "
              :vmodel.sync="form.display_name"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              labelFor="display_about"
              labelName="Display About Name "
              :vmodel.sync="form.display_about"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
          </div> -->

          <!-- <div class="form-group row">
            <input-image
              labelFor="display_picture"
              labelName="Display About"
              :vmodel.sync="display_picture"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="displayPictureSrc"
              imgClass="display-img"
            />

            <input-image
              labelFor="display_logo"
              labelName="Display Logo"
              :vmodel.sync="display_logo"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="displayLogoSrc"
              imgClass="display-img"
            />

            <input-image
              labelFor="display_cover"
              labelName="Display Cover Image"
              :vmodel.sync="display_cover_image"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="displayCoverSrc"
              imgClass="display-img"
            />
          </div> -->

          <div class="form-group row">
            <input-text
              labelFor="banner_text"
              labelName="Banner Text "
              :vmodel.sync="form.banner_text"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3"
            />
            <input-text
              labelFor="help_text"
              labelName="Help Text "
              :vmodel.sync="form.help_text"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3"
            />
            <input-text
              labelFor="appointment_link"
              labelName="Appointment Link"
              :vmodel.sync="form.appointment_url"
              formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-3"
            />
          </div>
          <div class="form-group row">
            <!-- <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mb-4">
              <label for="">Bio</label>
              <ckeditor :editor="editor" v-model="form.agent_bio"></ckeditor>
            </div> -->
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mb-4">
              <figure>Where can I see this information?</figure>
              <img
                src="@/assets/images/display-setting.png"
                class="img-fluid"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating..." : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "DownlineRepDisplaySetting",
  props: {
    settings: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
    appointmentUrl: {
      required: false,
    },
  },
  data: () => ({
    editor: ClassicEditor,
    form: {
      // agent_bio: "",
      // display_no_cart_flag: 0,
      // display_about_flag: 0,
      // display_about: "",
      // display_phone_flag: 0,
      agent_web_name: "",
      display_phone: "",
      display_email: "",
      // display_email_flag: "",
      tagline: "",
      banner_text: "",
      help_text: "",
      agent_id: "",
      appointment_url: "",
    },
    isUpdate: false,
    display_picture: null,
    display_logo: null,
    display_cover_image: null,
    displayPictureSrc: "",
    displayLogoSrc: "",
    displayCoverSrc: "",
  }),
  methods: {
    updateInfo() {
      this.isUpdate = true;
      const url = `v2/update-display-setting`;
      let loader = this.$loading.show();
      // let formData = new FormData();
      // for (const [key, value] of Object.entries(this.form)) {
      //   if (value) {
      //     formData.append(key, value);
      //   }
      // }
      // if (this.display_logo) {
      //   formData.append("display_logo", this.display_logo);
      // }
      // if (this.display_cover_image) {
      //   formData.append("display_cover_image", this.display_cover_image);
      // }
      // if (this.display_picture) {
      //   formData.append("display_picture", this.display_picture);
      // }

      Api.put(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
  },
  watch: {
    settings: function (value) {
      if (value) {
        this.form = {
          // agent_bio: value.agent_bio ? value.agent_bio : "",
          // display_phone_flag: value.display_phone_flag == 1 ? true : false,
          // display_about_flag: value.display_about_flag == 1 ? true : false,
          // display_no_cart_flag: value.display_no_cart_flag == 1 ? true : false,
          // display_email_flag: value.display_email_flag == 1 ? true : false,
          // display_about: value.display_about,
          agent_web_name: value.display_name,
          display_phone: value.display_phone,
          display_email: value.display_email,
          tagline: value.tagline,
          banner_text: value.banner_text,
          help_text: value.help_text,
          appointment_url: this.appointmentUrl,
          agent_id: parseInt(atob(this.$route.params.downlineRepId)),
        };
        this.displayPictureSrc = value.display_picture
          ? value.display_picture
          : null;
        this.displayLogoSrc = value.display_logo ? value.display_logo : null;
        this.displayCoverSrc = value.display_cover_image
          ? value.display_cover_image
          : null;
      }
    },
    appointmentUrl: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.form.appointment_url = value;
        }
      },
    },
  },
};
</script>
