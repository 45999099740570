<template>
  <div class="info-wrapper" ref="downlineRepBusinessContainer">
    <h2>Business Information</h2>
    <p>Enter and Update all your Business Information here.</p>
    <div class="information-form mt-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateBusinessInfo)">
          <div class="form-group row">
            <input-text
              :rules="{ required: true }"
              labelFor="business_name"
              labelName="Business Name "
              :vmodel.sync="form.business_name"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: true }"
              labelFor="business_tax_id"
              labelName="Business TAX ID "
              :vmodel.sync="form.business_tax_id"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
          </div>

          <div class="form-group row image-row">
            <input-image
              @deleteImage="deleteImage('business')"
              ref="businessLogo"
              labelFor="business_logo"
              labelName="Business Logo"
              :vmodel.sync="business_logo"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="businessLogoSrc"
              imgClass="downline-upload-img"
            />

            <input-image
              @deleteImage="deleteImage('business_cover')"
              ref="businessCoverImage"
              labelFor="business_cover_image"
              labelName="Business Cover Image"
              :vmodel.sync="business_cover_image"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="businessCoverImageSrc"
              imgClass="downline-upload-img"
            />
          </div>

          <downline-rep-business-address-content
            :addresses="businessAddresses"
          />

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating..." : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import DownlineRepBusinessAddressContent from "./partials/DownlineRepBusinessAddressContent.vue";
export default {
  name: "DownlineRepBusinessInfo",
  components: { DownlineRepBusinessAddressContent },
  props: {
    businessInfo: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
  },
  data: () => ({
    form: {
      business_name: "",
      business_tax_id: "",
      agent_id: "",
    },
    isUpdate: false,
    businessAddresses: [],
    business_logo: null,
    business_cover_image: null,
    businessLogoSrc: null,
    businessCoverImageSrc: null,
  }),
  methods: {
    async updateBusinessInfo() {
      this.isUpdate = true;
      const url = `v2/update-agent-business`;
      let loader = this.$loading.show();
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      if (this.business_logo) {
        formData.append("image", this.business_logo);
      }
      if (this.business_cover_image) {
        formData.append("cover_image", this.business_cover_image);
      }

      await Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSucess");
          this.$refs.businessLogo.reset();
          this.$refs.businessCoverImage.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
     deleteImage(type) {
     this.$emit('deleteImage',type)
    },
  },
  watch: {
    businessInfo: function (value) {
      if (value) {
        this.form = {
          business_name: value.business_name,
          business_tax_id: value.business_tax,
          agent_id: parseInt(atob(this.$route.params.downlineRepId)),
        };
        this.businessLogoSrc = value.business_logo ? value.business_logo : "";
        this.businessCoverImageSrc = value.business_cover_image
          ? value.business_cover_image
          : "";
        this.businessAddresses = value.all_address ?? [];
      }
    },
  },
};
</script>