var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pach form-ach"
  }, [_c('h3', [_vm._v(" ACH "), _c('i', {
    staticClass: "fas fa-plus-circle",
    on: {
      "click": _vm.addAchPayment
    }
  })]), _c('div', {
    staticClass: "fileListing-card"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [!_vm.achPayments ? _c('tr', [_c('td', {
    attrs: {
      "colspan": 4,
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.achPayments, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(row.achpayment_name))])]), _c('td', [_c('span', {
      staticClass: "username"
    }, [_vm._v(_vm._s(row.achpayment_bankname))])]), _c('td', [_c('span', {
      staticClass: "username-code"
    }, [_vm._v(_vm._s(row.achpayment_account))])]), _c('td', [_c('span', {
      staticClass: "username"
    }, [_vm._v(_vm._s(row.achpayment_routing))])]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [row.is_primary ? [_c('button', {
      staticClass: "primaryAdrs-btn"
    }, [_vm._v("Primary")])] : [_c('button', {
      staticClass: "primaryAdrs-btn",
      attrs: {
        "href": "javascript:void(0);"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.setPrimary(row.achpayment_id);
        }
      }
    }, [_vm._v(" Set As Primary ")])], !row.is_primary ? _c('custom-button', {
      attrs: {
        "className": "ai-delete-a",
        "title": "Delete",
        "iconClass": "fas fa-trash"
      },
      nativeOn: {
        "click": function click($event) {
          return _vm.deleteAddress(row.achpayment_id);
        }
      }
    }) : _vm._e()], 2)])]);
  })], 2)])]), _c('div', {
    staticClass: "table-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.achPayments,
      "filters": []
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.achPayments = $event;
      }
    }
  })], 1)]), _vm._m(1), _c('downline-rep-ach-payment-add')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Bank")]), _c('th', [_vm._v("Account Number")]), _c('th', [_vm._v("Routing")]), _c('th', [_vm._v("Action")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
  }, [_c('button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Update")])])]);
}]

export { render, staticRenderFns }