var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "addAddress-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "downlineRepAddressAdd",
      "centered": "",
      "size": "lg",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addAddress-header"
        }, [_c('h4', [_vm._v("Add New " + _vm._s(_vm.type) + " Address")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "addAddress-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "addAddressContainer",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.addAddress);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "address1",
            "labelName": "Address Line 1",
            "inputName": "address1",
            "inputClass": "address1 info-field",
            "vmodel": _vm.form.address1,
            "formGroupClass": "col-xxl-6 col-lg-6 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "address1", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "address2",
            "labelName": "Address Line 2",
            "inputName": "address2",
            "inputClass": "address2 info-field",
            "vmodel": _vm.form.address2,
            "formGroupClass": "col-xxl-6 col-lg-6 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "address2", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "city",
            "labelName": "City",
            "inputName": "city",
            "inputClass": "city info-field",
            "vmodel": _vm.form.city,
            "formGroupClass": "col-xxl-6 col-lg-6 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "city", $event);
            }
          }
        }), _c('input-multi-select', {
          attrs: {
            "rules": {
              required: true
            },
            "vmodel": _vm.form.state,
            "labelName": "State",
            "options": _vm.states.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.states.find(function (x) {
                return x.value == opt;
              }).text;
            },
            "formGroupClass": "col-xxl-6 col-lg-6 col-md-6 mb-3",
            "placeholder": "Select State",
            "inputClass": "state"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "state", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true,
              digits: 5
            },
            "labelFor": "zip",
            "labelName": "Zip",
            "inputName": "zip",
            "vmodel": _vm.form.zip,
            "type": "number",
            "formGroupClass": "col-xxl-6 col-lg-6 col-md-6 mb-3",
            "inputClass": "zip info-field"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "zip", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
        }, [_c('toggle-element', {
          attrs: {
            "vmodel": _vm.form.is_primary,
            "text": "Set as Primary"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "is_primary", $event);
            },
            "onClicked": function onClicked($event) {
              _vm.form.is_primary = !_vm.form.is_primary;
            }
          }
        })], 1), _c('div', {
          staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.continue_with_address_validation,
            expression: "continue_with_address_validation"
          }],
          staticClass: "form-check-input add-new-address-checkbox",
          attrs: {
            "type": "checkbox",
            "id": "flexCheckDefault"
          },
          domProps: {
            "checked": Array.isArray(_vm.continue_with_address_validation) ? _vm._i(_vm.continue_with_address_validation, null) > -1 : _vm.continue_with_address_validation
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.continue_with_address_validation,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && (_vm.continue_with_address_validation = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.continue_with_address_validation = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.continue_with_address_validation = $$c;
              }
            }
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "flexCheckDefault"
          }
        }, [_vm._v(" Validate with USPS ")])])]), _c('div', {
          staticClass: "w-100 text-center"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.isCreate ? "Creating" : "Create") + " ")])], 1)])];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }