<template>
  <div class="info-wrapper">
    <h2>Rep Group</h2>
    <p>Enter and Update all your Rep Group here.</p>
    <div class="information-form mt-4">
      <form @submit.prevent="updateGroups" ref="downlineRepGroupContainer">
        <div class="form-group row">
          <div class="col-xxl-12">
            <b-form-group>
              <validation-provider
                name="group"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <label id="group" for="groups"> Groups </label>
                <multiselect
                  v-model="selectedGroups"
                  :options-limit="200"
                  :options="groups"
                  label="name"
                  track-by="id"
                  key="id"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                >
                </multiselect>
                <b-form-invalid-feedback id="input-feedback ">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
            <button type="submit" class="univ-btn">
              {{ isUpdate ? "Updating..." : "Update" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "DownlineRepGroup",
  props: {
    repGroups: {
      required: true,
    },
     tabIndex: {
      required: true,
    },
  },
  data: () => ({
    groups: [],
    selectedGroups: [],
    form: {
      agent_id: "",
      group_ids: [],
    },
    isUpdate: false,
  }),
  methods: {
    updateGroups() {
      this.update = true;
      const url = `v2/update-rep-group`;
      this.form.group_ids = this.selectedGroups.map((el) => el.id);
      let loader = this.$loading.show();
      Api.put(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
        });
    },
    async getGroups() {
      const url = "v2/get-rep-group-list";
      await Api.get(url)
        .then((res) => {
          this.groups = res.data.data.map((elem) => ({
            id: elem.group_id,
            name: elem.group_name,
          }));
          this.selectedGroups = this.repGroups.map((elem) => ({
            id: elem.group_id,
            name: elem.group_name,
          }));
          this.form.agent_id = parseInt(atob(this.$route.params.downlineRepId));
        })
        .catch(() => {
          console.log("err");
        });
    },
  },
  created() {
    this.getGroups();
  },
};
</script>