<template>
  <div class="info-wrapper">
    <h2>
      Personalized Sites
      <i
        class="fas fa-edit site-edit-icon"
        title="Edit"
        @click="showEditModal"
      ></i>
    </h2>
    <p>Enter and edit landing page url here</p>
    <!-- <span><strong>Rep Code: </strong> {{ personalInfo.agent_code }}</span> -->
    <div class="information-form mt-4" v-if="personalizedSites.length">
      <div v-for="(sites, index) in personalizedSites" :key="index">
        <div class="d-flex site-detail">
          <div class="site-img" v-if="sites.image">
            <img :src="sites.image" alt="" class="img-fluid" />
          </div>
          <div class="site-info">
            <h3>{{ sites.name }}</h3>
            <span class="url-site"
              ><a :href="sites.domain" target="_blank">{{
                sites.domain
              }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-danger">Currently there are no personalized sites</p>
    </div>
    <personalized-site-edit
      :webAccessCode="webAccessCode"
      :updateUrl="updateUrl"
      @onSuccess="getSites"
    />
  </div>
</template>

<script>
import PersonalizedSiteEdit from "../../Dashboard/partials/PersonalizedSiteEdit.vue";
import Api from "@/resource/Api";

export default {
  name: "DownlineRepPersonalizedSites",
  components: {
    PersonalizedSiteEdit,
  },
  props: {
    downlineRepId: String,
  },
  data() {
    return {
      updateUrl: "",
      webAccessCode: "",
      personalizedSites: [],
    };
  },
  methods: {
    showEditModal() {
      this.$bvModal.show("updatePersonalizedSite");
    },
    async getSites() {
      const url = "v2/get-personalized-site?agent_id=" + this.downlineRepId;
      await Api.get(url)
        .then((res) => {
          if (res.status == 200) {
            this.personalizedSites = res.data.data.sites;
            this.webAccessCode = res.data.data.web_access_code ?? "";
          }
        })
        .catch(() => {
          this.personalizedSites = [];
        });
    },
  },
  created() {
    this.updateUrl =
      "v2/update-personalized-site?agent_id=" + this.downlineRepId;
    this.getSites();
  },
};
</script>

<style scoped>
.site-detail {
  padding: 10px 0;
}
.site-img {
  max-width: 80px;
  max-height: 80px;
  margin-right: 18px;
  overflow-y: hidden;
}

.site-edit-icon {
  margin-left: 3px;
  color: #03c3ec;
  cursor: pointer;
}
.information-form .site-detail {
  border: 1px solid #ebebeb;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.information-form .site-detail .site-img img {
  padding-top: 8px;
}
.information-form .site-detail h3 {
  margin-bottom: 5px;
}
.information-form .site-detail a {
  font-size: 14px;
}
</style>
<style src="@/assets/css/repdashboard.css"></style>
