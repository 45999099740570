<template>
  <div class="info-wrapper">
    <h2>Payment Information</h2>
    <p>Select payment method or add new one.</p>
    <div class="paymentInfo-form" id="selector">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input bank-details-switch"
          id="customSwitch1"
          v-model="isAch"
          :value="isAch"
          @input="changePaymentMethod"
        />
        <label class="custom-switch-span" for="customSwitch1">
          Supply bank information at a later date
        </label>
        <label class="custom-control-label" for="customSwitch1">ACH</label>
      </div>

      <template v-if="selectedPaymentMethod == 'check'">
        <downline-rep-check-payment-address-content
          :addresses="checkPaymentAddresses"
        />
      </template>
      <template v-if="selectedPaymentMethod == 'ach'">
        <downline-rep-ach-payment
          :ach-payments="achPayments"
          @onSuccessPaymentAdd="updatePaymentMethodAfterSuccess"
          @onPaymentModalClose="resetPayment"
        />
      </template>
    </div>
  </div>
</template>
<script>
import Api from '@/resource/Api'
import DownlineRepAchPayment from './partials/DownlineRepAchPayment.vue'
import DownlineRepCheckPaymentAddressContent from './partials/DownlineRepCheckPaymentAddressContent.vue'

export default {
  name: 'DownlineRepPaymentInfo',
  components: {
    DownlineRepAchPayment,
    DownlineRepCheckPaymentAddressContent,
  },
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
    checkPaymentAddresses: {
      required: true,
    },
    achPayments: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
  },
  data: () => ({
    selectedPaymentMethod: '',
    isAch: false,
  }),
  methods: {
    resetPayment(showModal = false) {
      let paymentMethod = this.paymentMethod
      if (paymentMethod == 'ach') {
        this.isAch = true
        this.selectedPaymentMethod = 'ach'
        if (this.achPayments.length < 1 && showModal) {
          this.$nextTick(() => {
            this.$bvModal.show('downlineRepAchAdd')
          })
        }
      } else {
        this.isAch = false
        this.selectedPaymentMethod = 'check'
        if (this.checkPaymentAddresses.length < 1 && showModal) {
          this.$nextTick(() => {
            this.$bvModal.show('downlineRepAddressAdd')
          })
        }
      }
    },
    updatePaymentMethodAfterSuccess(paymentType) {
      if (paymentType != this.selectedPaymentMethod) {
        this.updatePaymentMethod(paymentType)
      }
    },
    changePaymentMethod() {
      this.$confirm({
        title: 'Change Payment',
        message: `Do you want to change payment method?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            let newPayment = ''
            if (this.selectedPaymentMethod == 'check') {
              newPayment = 'ach'
              this.isAch = true
            } else {
              this.isAch = false
              newPayment = 'check'
            }

            if (newPayment == 'ach') {
              if (this.achPayments.length < 1) {
                //modal show
                this.selectedPaymentMethod = 'ach'
                this.$nextTick(() => {
                  this.$bvModal.show('downlineRepAchAdd')
                })
                this.$bvModal.show('downlineRepAchAdd')
              } else {
                this.updatePaymentMethod('ach')
              }
            } else if (newPayment == 'check') {
              if (this.checkPaymentAddresses.length < 1) {
                //modal show
                this.selectedPaymentMethod = 'check'
                this.$nextTick(() => {
                  this.$bvModal.show('downlineRepAddressAdd')
                })
              } else {
                //update payment
                this.updatePaymentMethod('check')
              }
            }
          } else {
            this.selectedPaymentMethod = this.paymentMethod
            if (this.selectedPaymentMethod == 'ach') {
              this.isAch = true
            } else {
              this.isAch = false
            }
          }
        },
      })
    },
    updatePaymentMethod(newPayment) {
      let loader = this.$loading.show()
      let url = `v2/update-agent-payment?default_payment_type=${newPayment}`
      let agentId = parseInt(atob(this.$route.params.downlineRepId))
      Api.post(url, { agent_id: agentId })
        .then((res) => {
          let response = res.data.message
          this.selectedPaymentMethod = newPayment
          this.$emit('onSuccess')
          this.$notify(response)
        })
        .catch((err) => {
          this.$notify(err.response.data.message, 'error')
          this.selectedPaymentMethod = this.paymentMethod
        })
        .finally(() => {
          loader.hide()
        })
    },
  },
  watch: {
    tabIndex: function (value) {
      if (value == 3) {
        this.resetPayment(true)
      }
    },
  },
}
</script>
<style src="@/assets/css/accountInfo.css"></style>
<style src="@/assets/css/myfiles.css"></style>
<style>
.bank-details-switch {
  margin-left: 28%;
}
@media (max-width: 640px) {
  .ach-label {
    margin-left: 40px !important;
    margin-bottom: 1rem !important;
  }
}
.downlineEdit-wrap
  .downlineEdit-card
  .fileListing-card
  .action-icons
  .primaryAdrs-btn {
  margin-top: 0;
}
#addAch .modal-content {
  border: 0px;
  border-radius: 10px;
}
#addAch .modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
  border-bottom: 0;
}
#addAch .modal-footer {
  border-top: 0;
  padding: 0 15px 25px 15px;
}
.addAch-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addAch-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addAch-form label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
#addAch .modal-footer .univ-btn {
  max-width: 300px;
}
</style>
