var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrapper"
  }, [_c('h2', [_vm._v("Rep Group")]), _c('p', [_vm._v("Enter and Update all your Rep Group here.")]), _c('div', {
    staticClass: "information-form mt-4"
  }, [_c('form', {
    ref: "downlineRepGroupContainer",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.updateGroups.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-12"
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "group",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('label', {
          attrs: {
            "id": "group",
            "for": "groups"
          }
        }, [_vm._v(" Groups ")]), _c('multiselect', {
          key: "id",
          attrs: {
            "options-limit": 200,
            "options": _vm.groups,
            "label": "name",
            "track-by": "id",
            "multiple": true,
            "close-on-select": false,
            "clear-on-select": false
          },
          model: {
            value: _vm.selectedGroups,
            callback: function callback($$v) {
              _vm.selectedGroups = $$v;
            },
            expression: "selectedGroups"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "id": "input-feedback "
          }
        }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
      }
    }])
  })], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
  }, [_c('button', {
    staticClass: "univ-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "Updating..." : "Update") + " ")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }