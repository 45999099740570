<template>
  <div class="info-wrapper">
    <h2>Hour of Operation (Business Hours)</h2>
    <div class="information-form mt-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.stop.prevent="handleSubmit(updateBusinessSetting)"
          ref="updateBusinessSettingContainer"
        >
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-3">
            <div class="form-group mb-3">
              <input-multi-select
               :rules="{ required: true }"
                :vmodel.sync="form.timezone"
                labelName="Timezone"
                :options="timezones.map((type) => type.value)"
                :custom-label="
                  (opt) => timezones.find((x) => x.value == opt).text
                "
                formGroupClass="form-group mb-3"
                placeholder="Select Timezone"
              />
            </div>

            <div class="form-group mb-3">
              <label for="">Monday - Friday</label>
              <div class="profileTime-picker">
                <date-picker
                 :rules="{ required: true }"
                  input-class="profileInfo-field"
                  placeholder="From"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.from"
                >
                </date-picker>

                <date-picker
                 :rules="{ required: true }"
                  input-class="profileInfo-field"
                  placeholder="To"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.to"
                ></date-picker>
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="">Saturday</label>
              <toggle-element
                :vmodel.sync="form.sat_open"
                @onClicked="saturdayCheckboxHandler"
                :text="form.sat_open ? 'Opened' : 'Closed'"
              />
              <div class="profileTime-picker">
                <date-picker
                  input-class="profileInfo-field"
                  placeholder="From"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.sat_from"
                  :disabled="saturdayDateDisable"
                ></date-picker>

                <date-picker
                  input-class="profileInfo-field"
                  placeholder="To"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.sat_to"
                  :disabled="saturdayDateDisable"
                ></date-picker>
              </div>
            </div>
            <div class="form-group mb-3">
              <label for="">Sunday</label>
              <toggle-element
                :vmodel.sync="form.sun_open"
                @onClicked="sundayCheckboxHandler"
                :text="form.sun_open ? 'Opened' : 'Closed'"
              />
              <div class="profileTime-picker">
                <date-picker
                  input-class="profileInfo-field"
                  placeholder="From"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.sun_from"
                  :disabled="sundayDateDisable"
                ></date-picker>

                <date-picker
                  input-class="profileInfo-field"
                  placeholder="To"
                  type="time"
                  format="hh:mm A"
                  value-type="format"
                  v-model="form.sun_to"
                  :disabled="sundayDateDisable"
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="col-xxl-12 mt-3 my-profile-bio">
            <div class="profileInfo-block">
              <input-textarea
              placeholder="Enter your bio here..."
              inputName="bio"
              :vmodel.sync="form.bio"
              labelName="Bio"
              labelFor="bio"
              formGroupClass="col-12 mb-3"
              :rules="{required:true}"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                <button type="submit" class="univ-btn">
                  <template v-if="isUpdate"> Updating ... </template>
                  <template v-else> Update </template>
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import InputTextarea from '../../../components/form/InputTextarea.vue';
export default {
  name: "BusinessSetting",
  components:{
    InputTextarea
  },
  props: {
    businessSettings: {
      required: true,
    },
    downLineRepId: {
      required: true,
    },
  },
  data: () => ({
    form: {
      from: "",
      to: "",
      sat_open: true,
      sat_from: "",
      sat_to: "",
      sun_from: "",
      sun_to: "",
      sun_open: true,
      bio: "",
      timezone: "",
    },
    isUpdate: false,
    sundayDateDisable: false,
    saturdayDateDisable: false,
    timezones: [
      { value: "Samoa (UTC-11)", text: "Samoa (UTC-11)" },
      { value: "Hawaii-Aleutian (UTC-10)", text: "Hawaii-Aleutian (UTC-10)" },
      { value: "Alaska (UTC-9)", text: "Alaska (UTC-9)" },
      { value: "Pacific (UTC-8)", text: "Pacific (UTC-8)" },
      { value: "Mountain (UTC-7)", text: "Mountain (UTC-7)" },
      { value: "Central (UTC-6)", text: "Central (UTC-6)" },
      { value: "Eastern (UTC-5)", text: "Eastern (UTC-5)" },
      { value: "Atlantic (UTC-4)", text: "Atlantic (UTC-4)" },
      { value: "Chamorro (UTC+10)", text: "Chamorro (UTC+10)" },
    ],
  }),
  methods: {
    updateBusinessSetting() {
      const url = `v2/save-business-info`;
      let loader = this.$loading.show({
        container: this.$refs.updateBusinessSettingContainer,
      });
      this.isUpdate = true;
      const businessInfo = { ...this.form, agent_id: this.downLineRepId };
      Api.put(url, businessInfo)
        .then((res) => {
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
    sundayCheckboxHandler() {
      let value = !this.form.sun_open;
      if (value) {
        this.sundayDateDisable = false;
      } else {
        this.sundayDateDisable = true;
        this.form.sun_from = this.businessSettings.sun_from;
        this.form.sun_to = this.businessSettings.sun_to;
      }
      this.form.sun_open = value;
    },
    saturdayCheckboxHandler() {
      let value = !this.form.sat_open;
      if (value) {
        this.saturdayDateDisable = false;
      } else {
        this.saturdayDateDisable = true;
        this.form.sat_from = this.businessSettings.sat_from;
        this.form.sat_to = this.businessSettings.sat_to;
      }
      this.form.sat_open = value;
    },
    getDatePickerClass(error, isValid) {
      let className = "";
      if (error) {
        className = "invalid-input-block";
      } else if (isValid) {
        className = "valid-input-block";
      } else {
        className = "";
      }
      return `${className} profileInfo-field`;
    },
  },
  watch: {
    businessSettings: function (value) {
      this.form = {
        from: value.from ? value.from : "",
        to: value.to ? value.to : "",
        sat_open: value.sat_open == 1 ? true : false,
        sat_from: value.sat_from ? value.sat_from : "",
        sat_to: value.sat_to ? value.sat_to : "",
        sun_from: value.sun_from ? value.sun_from : "",
        sun_to: value.sun_to ? value.sun_to : "",
        sun_open: value.sun_open == 1 ? true : false,
        bio: value.bio ? value.bio : "",
        timezone: value.timezone ? value.timezone : "",
      };
      if (value.sat_open) {
        this.saturdayDateDisable = false;
      } else {
        this.saturdayDateDisable = true;
        this.form.sat_from = value.sat_from;
        this.form.sat_to = value.sat_to;
      }
      if (value.sun_open) {
        this.sundayDateDisable = false;
      } else {
        this.sundayDateDisable = true;
        this.form.sun_from = value.sun_from;
        this.form.sun_to = value.sun_to;
      }
    },
  },
};
</script>
<style src="@/assets/css/profile.css"></style>

