<template>
  <div class="info-wrapper">
    <h2>Personal Information</h2>
    <p>Enter and Update all your Personal Information here.</p>
    <span><strong>Rep Code: </strong> {{ personalInfo.agent_code }}</span>

    <div class="information-form mt-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateInfo)">
          <div class="form-group">
            <!-- <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="superGa"
                v-model="form.super_ga"
                :value="form.super_ga"
              />
              <label class="form-check-label" for="superGa">Super GA</label>
            </div>

            <div class="form-check form-check-inline mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="chPricing"
                v-model="form.ch_pricing"
                :value="form.ch_pricing"
              />
              <label class="form-check-label" for="chPricing">CH Pricing</label>
            </div> -->
          </div>

          <div class="form-group row">
            <input-text
              :rules="{ required: true }"
              labelFor="agent_fname"
              labelName="First Name"
              :vmodel.sync="form.first_name"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              labelFor="agent_mname"
              labelName="Middle Name "
              :vmodel.sync="form.middle_name"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: true }"
              labelFor="agent_lname"
              labelName="Last Name"
              :vmodel.sync="form.last_name"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: true, neverbounce: true }"
              labelFor="agent_email"
              labelName="Email Address"
              :vmodel.sync="form.email"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <!-- <input-text
              labelFor="agent_upline"
              labelName="Upline Agent Code "
              :vmodel.sync="form.agent_upline"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            /> -->

            <input-text
              :rules="{ required: true, digits: 10, clearoutPhone: true }"
              labelFor="agent_phone1"
              labelName="Cell Phone"
              :vmodel.sync="form.cell_phone"
              placeholder="Enter Phone 1 (no dashes eg: 123456789)"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
              inputType="number"
            />
            <input-text
              :rules="{ required: false, digits: 10, clearoutPhone: true }"
              labelFor="agent_phone2"
              labelName="Telephone "
              :vmodel.sync="form.telephone"
              placeholder="Enter Phone 2 (no dashes eg: 123456789)"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
              inputType="number"
            />

            <input-text
              labelFor="fax"
              labelName="FAX "
              :vmodel.sync="form.fax"
              placeholder="Enter Fax (no dashes eg: 123456789)"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              labelFor="num_800"
              labelName="800 Number "
              :vmodel.sync="form.num_800"
              placeholder="Enter 800 Number (no dashes eg: 123456789)"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <div class="clearfix"></div>

            <input-image
              :rules="{ required: false }"
              @deleteImage="deleteImage('personal')"
              ref="image"
              labelFor="image"
              labelName="Upload Image"
              :vmodel.sync="image"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4 image-row"
              :imgSrc="agentImg"
              imgClass="downline-upload-img"
            />
          </div>

          <downline-rep-personal-address-content
            v-if="personalAddresses"
            :addresses="personalAddresses"
          />

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating..." : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import DownlineRepPersonalAddressContent from "./partials/DownlineRepPersonalAddressContent.vue";
export default {
  name: "DownlineRepPersonalInfo",
  components: { DownlineRepPersonalAddressContent },
  props: {
    agentImg: {
      required: true,
    },
    personalInfo: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
  },
  data: () => ({
    form: {
      agent_id: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      cell_phone: "",
      fax: "",
      telephone: "",
      num_800: "",
      email: "",
    },
    image: null,
    personalAddresses: [],
    states: Helper.getStates(),
    isUpdate: false,
  }),
  methods: {
    updateInfo() {
      this.isUpdate = true;
      const url = `v2/update-agent-personal`;
      let loader = this.$loading.show();

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      if (this.image) {
        formData.append("image", this.image);
      }

      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSucess");
          this.$refs.image.reset();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
    deleteImage(type) {
     this.$emit('deleteImage',type)
    },
  },
  watch: {
    personalInfo: function (value) {
      if (value) {
        this.form = {
          agent_id: value.agent_id,
          first_name: value.agent_fname,
          middle_name: value.agent_mname ? value.agent_mname : "",
          last_name: value.agent_lname,
          cell_phone: value.agent_phone1,
          fax: value.agent_fax,
          telephone: value.agent_phone2,
          num_800: value.agent_800,
          email: value.agent_email,
        };
        this.personalAddresses = value.address_list;
      }
    },
  },
};
</script>
<style>
.ai-location-wrapper {
  margin-top: 15px;
}
.ai-location-wrapper label {
  font-size: 16px !important;
  font-weight: bold;
}
.location-div {
  border: 2px solid #dce1e7;
  padding: 10px;
  border-radius: 6px;
}
.location-div p {
  display: inline-block;
  margin-bottom: 0;
}
.location-div button {
  float: right;
  margin-top: 3px;
  padding: 6px 12px;
  margin-right: 35px;
}
.location-div.primary-location {
  border: 2px solid #03c3ec;
}
.location-div.primary-location .primaryAdrs-btn {
  pointer-events: none;
}
.location-div.set-as-primary .primaryAdrs-btn {
  border: 2px solid #03c3ec;
  background-color: #fff;
  color: #03c3ec;
}
.location-div.set-as-primary .primaryAdrs-btn:hover {
  border: 2px solid #03c3ec;
  background-color: #03c3ec;
  color: #fff;
}
.fa-check-circle {
  color: #02bc77;
}
.fa-plus-circle {
  color: #03c3ec;
  font-size: 16px;
  margin-left: 3px;
  vertical-align: revert;
  margin-bottom: 5px;
}
.fa-exclamation-triangle {
  color: #f29423;
}

.location-div {
  position: relative;
}
.location-div .ai-delete-a {
  background: transparent linear-gradient(180deg, #0cf, #1597b8 49%, #0567bc);
  border-radius: 50px;
  width: 27px;
  height: 27px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px 8px;
  position: absolute;
  right: 2px;
  top: 6px;
}
.form-ach .action-icons .primaryAdrs-btn {
  margin-right: 2px;
}
.form-ach .action-icons a {
  margin: 7px;
}

@media (max-width: 850px) and (min-width: 466px) {
  .location-div .primaryAdrs-btn {
    margin-right: 27px;
    padding: 6px 6px;
  }
  .location-div .ai-delete-a {
    margin: 10px 2px;
  }
}
@media (max-width: 465px) {
  .location-div .primaryAdrs-btn {
    padding: 6px 6px;
    float: none;
    margin-right: 0px !important;
    width: 80%;
    margin-top: 15px;
  }
  .location-div .ai-delete-a {
    top: unset !important;
    bottom: 5px;
  }
  .ai-image-wrapper .img-fluid {
    margin-right: 2px;
  }
  .ai-image-wrapper input {
    margin-left: 45px !important;
  }
  .ai-image-wrapper input::before {
    padding: 8px 10px;
    margin-right: -10px;
  }
  .downlineEdit-card {
    padding: 1rem;
  }
}
.uploaded {
  width: 104%;
}
</style>