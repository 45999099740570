var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.isPrimary ? [_c('button', {
    staticClass: "primaryAdrs-btn"
  }, [_vm._v("Primary")])] : [_c('a', {
    staticClass: "primaryAdrs-btn rep-action-btn",
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.setPrimary(_vm.addressId, _vm.type);
      }
    }
  }, [_vm._v(" Set As Primary ")])], !_vm.isPrimary ? _c('custom-button', {
    attrs: {
      "className": "ai-delete-a",
      "title": "Delete",
      "iconClass": "fas fa-trash"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.deleteAddress(_vm.addressId, _vm.type);
      }
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }